import React, { useCallback, useEffect, useMemo, useState } from 'react'
import useStore from '@store/useStore'
import { useNavigate } from 'react-router-dom'
import { generateRoute, ROUTES } from '@const/Routes'

import { useThreadUI } from '@hooks/useThreadUI'
import {
  createAssistantThreadWithInput,
  markAssistantComponentThreadAsCompleted,
} from '@services/Insights'
import { sendThreadMessage, useThread } from '@services/Assistant'

import { Mission } from '@edwinsandbox/react-web'
import { OPageWrapper } from '@organisms/OPageWrapper'
import { MChatInput } from '@molecules/MChatInput'

export const OFlow = ({ children, assistantId }) => {
  const navigate = useNavigate()

  const [thread] = useThread(assistantId)

  const [isNewMessagesLoading, setIsNewMessagesLoading] = useState(false)

  const user = useStore(state => state.user)

  const isLastMsgNeedsUserAction = useMemo(() => {
    const lastMsg = thread?.messages?.[thread?.messages?.length - 1]
    const validTypes = {
      'selectable-options': true,
      'date-time-picker': true,
      'numerical-range': true,
    }

    return validTypes[lastMsg?.data?.type] === true
  }, [thread?.messages])

  const sendMessage = useCallback(
    async (message, hidden) => {
      setIsNewMessagesLoading(true)

      await sendThreadMessage({ threadId: assistantId, message, hidden })
    },
    [assistantId]
  )

  const { modifiedThread, handleUserInput } = useThreadUI({
    thread,
    handleNewMessage: sendMessage,
  })

  const handleAssistantFlowAction = useCallback(
    async ({ type, input }) => {
      try {
        let navigateTo = ROUTES.ROOT

        const shouldContinueFlow = !!type && type !== 'end'

        if (shouldContinueFlow) {
          const { id } = await createAssistantThreadWithInput({
            type,
            input,
          })

          navigateTo = generateRoute(ROUTES.ASSISTANT, { assistantId: id })
        }

        if (thread?.id) {
          await markAssistantComponentThreadAsCompleted({ threadId: thread.id })
        }

        navigate(navigateTo)
      } catch (error) {
        console.error(error)
      }
    },
    [navigate, thread?.id]
  )

  useEffect(() => {
    const isRemoteThreadLoading = thread?.inProgress

    setIsNewMessagesLoading(isRemoteThreadLoading)
  }, [thread])

  return (
    <OPageWrapper
      className="flex flex-col h-full"
      contentClassName="flex flex-col h-full max-h-full !p-0 !pb-0.5"
    >
      <div>{children}</div>
      <div className="flex-1 flex flex-col max-h-full overflow-hidden">
        <div className="h-full pb-2 overflow-hidden">
          {!!thread && (
            <Mission
              user={user}
              thread={modifiedThread}
              onAssistantAction={handleAssistantFlowAction}
              onSendHiddenMsg={msg => {
                sendMessage(msg, true)
              }}
              shouldDisplayCover={false}
              shouldDisplaySidebar={false}
              shouldScrollItself
              isNewMessagesLoading={isNewMessagesLoading}
            />
          )}
        </div>
        <MChatInput
          handleOnSubmit={handleUserInput}
          isInputDisabled={isNewMessagesLoading || isLastMsgNeedsUserAction}
          isNewMessagesLoading={isNewMessagesLoading}
          className="px-2.5 sm:px-0"
        />
      </div>
    </OPageWrapper>
  )
}
