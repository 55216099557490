import React, { useEffect, useState, useCallback, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import useAIHomeStore from '@store/useAIHomeStore'

import { generateRoute, ROUTES } from '@const/Routes'

import {
  createInsightsAssistantThread,
  createAssistantThreadWithInput,
  createCheckinAssistantThreadOnRequest,
} from '@services/Insights'
import {
  generateUserCheckupConversation,
  dangerouslyCreateCheckupConversation,
  useCatchupConversationCompleted,
} from '@services/Catchup'

import CONFIG from '@config/Config'

import { MTabs } from '@molecules/MTabs'
import { OModal } from '@organisms/OModal'
import { MInsightsPrompt } from '@molecules/MInsightsPrompt'
import { OProgressSimulation } from '@organisms/OProgressSimulation'
import { OAIViewSimulation } from '@organisms/OAIViewSimulation'
import { OProofOfConcept } from '@organisms/OProofOfConcept'

const PROMPT_MODAL_KEYBOARD_KEY_CODE = 73 // 'i'

const useKeyEvents = (keyCode, callback) => {
  const [keyPressed, setKeyPressed] = useState(false)

  useEffect(() => {
    const handleKeyDown = e => {
      if (e.ctrlKey && e.keyCode === keyCode) {
        e.preventDefault()
        setKeyPressed(true)
        callback()
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [keyCode, callback])

  return keyPressed
}

export const ODevelopmentMode = () => {
  const navigate = useNavigate()

  const TABS = [
    { name: 'Insights' },
    ...(CONFIG?.allowUserSimulateProgress ? [{ name: 'Progress' }] : []),
    { name: 'AI View' },
    { name: 'Demo' },
  ]

  const { markCatchupConversationAsCompleted } = useCatchupConversationCompleted()

  const [isLoading, setIsLoading] = useState(false)
  const [currentTab, setCurrentTab] = useState(TABS[0].name)
  const [isPromptModalOpen, setIsPromptModalOpen] = useState(false)
  const isInitialInsightGenerated = useRef(false)

  const setDataFromJSON = useAIHomeStore(state => state.setDataFromJSON)

  const setCatchupMission = useAIHomeStore(state => state.setCatchupMission)

  const handleTogglePromptModal = useCallback(() => {
    setIsPromptModalOpen(!isPromptModalOpen)
  }, [isPromptModalOpen])

  useKeyEvents(PROMPT_MODAL_KEYBOARD_KEY_CODE, () => {
    handleTogglePromptModal()
  })

  const generateInsights = useCallback(async prompt => {
    try {
      setIsLoading(true)
      await createInsightsAssistantThread(prompt)

      isInitialInsightGenerated.current = true
    } catch (error) {
      throw new Error(error)
    } finally {
      setIsLoading(false)
      setIsPromptModalOpen(false)
    }
  }, [])

  const generateAssistantThreadWithInput = useCallback(
    async ({ type, input }) => {
      try {
        setIsLoading(true)
        const { id } = await createAssistantThreadWithInput({ type, input })

        navigate(generateRoute(ROUTES.ASSISTANT, { assistantId: id }))
      } catch (error) {
        throw new Error(error)
      } finally {
        setIsLoading(false)
        setIsPromptModalOpen(false)
      }
    },
    [navigate]
  )

  const generateAIView = useCallback(
    async jsonData => {
      try {
        setIsLoading(true)

        setDataFromJSON(jsonData)
      } catch (error) {
        throw new Error(error)
      } finally {
        setIsLoading(false)
        setIsPromptModalOpen(false)
      }
    },
    [setDataFromJSON]
  )

  const handleCatchupTrigger = useCallback(async (retryCount = 0) => {
    const MAX_RETRY_COUNT = 1

    try {
      setIsLoading(true)

      const { data } = await generateUserCheckupConversation()
      // const { data } = await dangerouslyCreateCheckupConversation()

      console.log('---Fetched catchup:', data)

      // setCatchupMission({
      //   id: 'catchup',
      //   title: 'Catchup mission',
      //   messages: data,
      // })

      setIsLoading(false)
      setIsPromptModalOpen(false)
    } catch (err) {
      console.log(err)
      // throw new Error(err)

      // Recursively allow for a retry {MAX_RETRY_COUNT} before throwing error
      if (retryCount < MAX_RETRY_COUNT) {
        console.warn('fetchData error (but trying again):', err)

        retryCount++

        return handleCatchupTrigger(retryCount)
      } else {
        console.warn('fetchData error final:', err)

        setIsLoading(false)
        setIsPromptModalOpen(false)
      }
    }
  }, [])

  const handleCatchupClear = useCallback(async () => {
    setIsPromptModalOpen(false)

    try {
      await markCatchupConversationAsCompleted()
    } catch (error) {
      throw new Error(error)
    }
  }, [markCatchupConversationAsCompleted])

  return (
    <OModal isOpen={isPromptModalOpen} onClose={() => setIsPromptModalOpen(false)}>
      {TABS.length > 1 && (
        <MTabs tabs={TABS} currentTab={currentTab} onTabClick={setCurrentTab} className="mb-3" />
      )}

      {currentTab === 'Insights' && (
        <MInsightsPrompt
          onPromptClick={async prompt => {
            await generateInsights(prompt)
          }}
        />
      )}

      {currentTab === 'Progress' && <OProgressSimulation />}

      {currentTab === 'AI View' && (
        <OAIViewSimulation
          onScenarioClick={scenario => {
            generateAIView(scenario)
          }}
          onCatchupTrigger={handleCatchupTrigger}
          onCatchupClear={handleCatchupClear}
        />
      )}

      {currentTab === 'Demo' && (
        <OProofOfConcept
          onAssistantClick={async ({ type, input }) => {
            await generateAssistantThreadWithInput({ type, input })
          }}
        />
      )}
    </OModal>
  )
}
